<!-- 微信用户列表 -->
<template>
  <div class="warp">
    <el-card class="warp-content">
      <!-- 搜素区域 -->
      <div class="page-select">
        <el-row style="width: 100%; margin: 0 0 15px 0">
          <el-col :span="24" style="text-align: right">
            <el-input
                placeholder="请输入用户昵称"
                class="search-input"
                prefix-icon="search"
                size="small"
                @input="searchInput"
                v-model="searchDeviceMac"
            >
            </el-input>
            <el-input
                placeholder="请输入手机号"
                class="search-input"
                prefix-icon="search"
                size="small"
                @input="searchInput"
                v-model="searchOrgName"
            >
            </el-input>
            <el-input
                placeholder="请输入用户ID"
                class="search-input"
                prefix-icon="search"
                size="small"
                @input="searchInput"
                v-model="searchDeviceAddress"
            >
            </el-input>
            <el-select
                clearable
                class="search-input"
                placeholder="请选择用户状态"
                size="small"
                v-model="searchDeviceAgency"
            >
              <el-option
                  v-for="item in deviceAgencyList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              >
                {{ item.label }}
              </el-option>
            </el-select>
            <el-form
                label-width="100px"
                :model="formData"
                :rules="rules"
                ref="ruleForm"
                class="formClass"
            >
              <el-form-item label="注册时间：" prop="alarTime">
                <el-date-picker
                    v-model="formData.alarTime"
                    unlink-panels
                    type="datetimerange"
                    size="small"
                    range-separator="至"
                    start-placeholder="起始时间"
                    end-placeholder="结束时间"
                    format="YYYY-MM-DD HH:mm:ss"
                    value-format="YYYY-MM-DD HH:mm:ss"
                    style="margin-top: 6px;"
                />
              </el-form-item>
            </el-form>
            <el-button type="primary" size="small" @click="searchClick"
            >搜索
            </el-button>
          </el-col>
        </el-row>
      </div>

      <!-- 表格区域 -->
      <div class="page-container">
        <el-table
            ref="tableRef"
            :data="tableData"
            style="width: 100%"
            max-height="630px"
            :header-cell-style="{ background: '#F2F4F8' }"
            @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" align="center" width="80" />
          <el-table-column
              v-for="(item, index) in tableColumnData"
              :key="index"
              :prop="item.prop"
              :label="item.label"
              align="center"
          >
            <template  #default="{ row }" v-if="item.prop === 'deviceImei'">
              <img src="@/assets/user/chips.png" />
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template #default="{ row }">
              <el-button type="text" @click="editClick(row)">编辑</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页区域 -->
      <div class="page-foot">
        <page-nation
            :total="total"
            @pageChange="handleSizeChange"
            @currentChange="handleCurrentChange"
        ></page-nation>
      </div>
      <!--编辑用户-->
      <el-dialog
          v-model="dialogVisible"
          width="40%"
          :title="dialogTitleName"
          :close-on-click-modal="false"
          @closed="resetForm"
      >
        <el-form
            label-width="95px"
            :inline="true"
            :model="editFormData"
            :rules="editDeviceRules"
            ref="editRuleFormRef"
        >
          <el-row :gutter="20">
            <el-col :span="22">
              <el-form-item label="昵称：" prop="deviceMac">
                <el-input
                    placeholder="请输入昵称"
                    disabled
                    v-model="editFormData.deviceMac"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="22">
              <el-form-item label="支付宝ID：" prop="deviceVersion">
                <el-input
                    placeholder="请输入支付宝ID"
                    disabled
                    v-model="editFormData.deviceVersion"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="22">
              <el-form-item label="状态：" prop="deviceStatus">
                <el-select
                    v-model="editFormData.deviceStatus"
                    placeholder="请选择状态"
                    class="search-input-class"
                    @change="jointFn" >
                  <el-option
                      v-for="item in deviceStatusOptions"
                      :key="item.v "
                      :label="item.label"
                      :value="item.value" >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <template #footer>
            <span class="dialog-footer">
              <el-button @click="resetEditForm">取消 </el-button>
              <el-button type="primary" @click="submitEditForm('allocateDeviceRuleFormRef')" >保存 </el-button>
            </span>
        </template>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, getCurrentInstance } from "vue-demi";
import pageNation from "@/components/base/pageNation.vue";
import { selectPagesApi } from "@/api/deviceManagement/deviceManagement.js";
import { requestApi } from "@/utils/requestApi";
import { debounces } from "@/common/utils";
export default {
  name: "AccountSearch",
  components: { pageNation },
  setup() {
    const { proxy } = getCurrentInstance();
    const data = reactive({
      searchValue: null, //搜索内容（用户名）
      searchContent: null, //搜索日志内容
      tableData: [], // 表格数据
      total: 0,
      pageSize: 10,
      currentPage: 1,
      userInfo: null,
      selection: [],
      disabled: true,
      formData: {
        alarTime: [], //起始时间
      },
      tableColumnData: [
        {
          prop: "deviceMac",
          label: "昵称",
        },
        {
          prop: "deviceSignal",
          label: "电话",
        },
        {
          prop: "deviceVersion",
          label: "支付宝ID",
        },
        {
          prop: "deviceImei",
          label: "头像",
        },
        {
          prop: "deviceAgency",
          label: "余额",
        },
        {
          prop: "orgName",
          label: "实际支付金额",
        },
        {
          prop: "deviceAddress",
          label: "赠送金额",
        },
        {
          prop: "deviceStatus",
          label: "状态",
        },
        {
          prop: "chargeName",
          label: "注册日期",
        },
        // {
        //   prop: "deviceChargnt",
        //   label: "收费方案",
        // },
      ],
      searchDeviceMac: "",
      searchOrgName: "",
      searchDeviceAddress: "",
      searchDeviceStatus: "",
      searchDeviceAgency: '',
      searchChargnt: '',
      deviceAgencyList: [
        {
          label: "特瑞萨",
          value: 0,
        },
      ],
      deviceChargntList: [{
        label: '1',
        value: 0
      }],
      // 编辑用户对话框
      dialogVisible: false,
      dialogTitleName:'编辑用户',
      // 编辑用户form
      editFormData:{
        deviceMac: '',
        deviceVersion:'',
        deviceStatus: ''
      },
      editDeviceRules: {},
      // 编辑用户 状态选项
      deviceStatusOptions:[
        {value: 0, label: '正常'},
        {value: 1, label: '禁用'},
      ],
    });
    onMounted(() => {
      //   proxy.userInfo = JSON.parse(localStorage.getItem("userInfo"));
      methods.selectPages();
    });
    // 节流搜索按钮
    const searchInput = debounces(() => {}, 500);
    const methods = {
      // 点击编辑
      editClick(row) {
        console.log(row);
        data.dialogVisible = true
        data.editFormData = {
          deviceMac: row.deviceMac,
          deviceVersion: row.deviceVersion,
          deviceStatus: row.deviceStatus,
        }
      },
      // 编辑确认
      submitEditForm() {
        data.dialogVisible = false
      },
      resetEditForm() {
        data.dialogVisible = false
      },
      searchClick() {
        console.log(
            proxy.searchDeviceMac,
            proxy.searchOrgName,
            proxy.searchDeviceAddress,
            proxy.searchDeviceStatus,
            proxy.searchDeviceAgency,
            proxy.searchChargnt
        );
      },
      async selectPages() {
        await requestApi(
            () => {
              const formdata = new FormData();
              formdata.append("address", "");
              formdata.append("deviceAgency", "");
              formdata.append("deviceCharge", "");
              formdata.append("deviceMac", "");
              formdata.append("deviceStatus", "");
              formdata.append("orgId", "");
              formdata.append("current", 1);
              formdata.append("pageSize", 10);

              return selectPagesApi(formdata);
            },
            (res) => {
              if (res.code === 200) {
                proxy.tableData = res.data.records.map((it) => {
                  return {
                    chargeName: it.chargeName, // 收费类型
                    deviceAddress: it.deviceAddress, //设备地址
                    deviceAgency: it.deviceAgency, // 代理商
                    deviceChargnt: it.deviceChargnt, // 收费方案
                    deviceId: it.deviceId, // 设备id
                    deviceImei: it.deviceImei, // imei号
                    deviceMac: it.deviceMac, // mac地址
                    devicePort: it.devicePort, // 设备端口
                    deviceSignal: it.deviceSignal, // 设备信号
                    deviceStatus: it.deviceStatus, // 设备状态
                    deviceVersion: it.deviceVersion, // 设备版本
                    orgId: it.orgId, // 充电站地址（主键）
                    orgName: it.orgName, // 充电站名称
                    updateTime: it.updateTime,
                    updateUser: it.updateUser,
                  };
                });
                console.log("成功", proxy.tableData);
              }
            }
        );
      },
    };
    return {
      ...toRefs(data),
      searchInput,
      ...methods,
    };
  },
};
</script>
<style lang="less" scoped>
.warp {
  height: 100%;
  .warp-content {
    height: 100%;
  }
}
.page-select {
  width: 100%;
  margin: 0 auto;
  .formClass {
    display: inline-block;
    margin-right: 4px;
  }
}
.page-container {
  width: 100%;
  margin-top: -28px;
}
.page-foot {
  width: 100%;
  margin-top: 14px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .my-btn {
    height: 28px;
  }
}
.search-input {
  width: 202px;
  margin-right: 4px;
  margin-top: 6px;
}

.search-input-class {
  width: 100%;
}
/deep/.el-form-item__label {
  white-space: nowrap;
}
/deep/.el-form-item {
  width: 100%;
}
</style>
