import { axios } from '@/utils/request'

const api = {
    selectPages: 'device/device/selectPages', // 查询设备
    addDevice: 'device/device/addDevice',    // 新增设备
    delDevice: 'device/device/delDevice',    // 删除设备
    upDevice: 'device/device/upDevice',      // 修改设备
    pages:'charging/charge/pages',           // 计费方案分页查询
    UpdCharge: 'device/device/UpdCharge',    // 批量或单个分配收费方案
    deriveXlsAll: 'device/device/deriveXlsAll', // 导出设备信息
    exportTemplate: 'device/device/exportTemplate', // 导出设备信息模板
    importXls: 'device/device/importXls', // 导入xls格式
    getByDeviceMac: 'device/device/occupy/getByDeviceMac', // 根据设备MAC查询占用的端口
    deviceSync: 'device/device/deviceSync',         // 根据设备mac号列表更新设备状态
}
export default api

// 查询设备
export const deviceSelectPagesApi = (params) => {
    return axios({
        url: api.selectPages,
        method: 'post',
        params: params
    })
}

// 新增设备
export const addDeviceApi = (params) => {
    return axios({
        url: api.addDevice,
        method: 'post',
        data: params
    })
}

// 删除设备
export const delDeviceApi = (data) => {
    return axios({
        url: api.delDevice,
        method: 'post',
        data
    })
}

// 修改设备
export const upDeviceAPi = (params) => {
    return axios({
        url: api.upDevice,
        method: 'post',
        data: params
    })
}

// 计费方案分页查询
export const getChargingSchemeAPi = (params) => {
    return axios({
        url: api.pages,
        method: 'post',
        params
    })
}

// 批量或单个分配收费方案
export const updChargeAPi = (params) => {
    return axios({
        url: api.UpdCharge,
        method: 'post',
        params
    })
}

// 导出设备信息
export const deriveXlsAllAPi = (params) => {
    return axios({
        url: api.deriveXlsAll,
        responseType: 'blob',
        method: 'get',
        params
    })
}

// 导出设备模板
export const exportTemplateAPi = (data) => {
    return axios({
        url: api.exportTemplate,
        responseType: 'blob',
        method: 'get',
        data
    })
}

// 导入设备信息
export const importXlsAPi = (data) => {
    return axios({
        url: api.importXls,
        responseType: 'blob',
        method: 'post',
        data
    })
}

export const getByDeviceMacAPi = (params) => {
    return axios({
        url: api.getByDeviceMac,
        method: 'post',
        params
    })
}

// 根据设备mac号列表更新设备状态
export const deviceSyncApi = (data) => {
    return axios({
        url: api.deviceSync,
        method: 'post',
        data
    })
}
